import { Injectable, Provider } from '@angular/core';
import { RadiusConfiguration } from '@portal/wen-widget';

@Injectable()
export class CokaRadiusConfiguration extends RadiusConfiguration {
  getCustomGeoRadius(): number[] {
    return [
      0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10
    ];
  }
  getDefaultRadius(): number {
    return 2;
  }
}

export const COKA_RADIUS_CONFIGURATION: Provider = {
  provide: RadiusConfiguration,
  useClass: CokaRadiusConfiguration,
};
