import { WenLanguage, WenNetwork } from '@portal/wen-backend-api';
import { EnvironmentType } from './environment-type';

export const environment: EnvironmentType = {
  production: true,
  versionType: 'development',
  backendConfig: {
    backendUrl: 'https://websocket.development.we.network/',
    mediaServiceBaseUrl: 'https://media.development.we.network/',
    network: WenNetwork.COKA
  },
  oauthConfig: {
    postLogoutRedirectUri: '/signin/start',
    clientId: 'wen-karlsruhe-2e7d630f-0304-453c-b7d2-fbffc265d2fb',
    issuer: 'https://identity.development.we.network',
    tokenEndpoint: 'https://identity.development.we.network/oauth/token',
    logoutUrl: 'https://identity.development.we.network/forcelogout',
    revocationEndpoint: 'https://identity.development.we.network/oauth/token/revoke'
  },
  weLocalBaseUrl: 'https://multimedia.we.network/',
  yellowMapConfig: {
    yellowMapUrl: 'https://www.yellowmap.de',
    yellowMapApiKey: 'dXsavGwBsiBmtLMq8+C0Y5iufdUC5/ZoS35Pu8v9Dv0='
  },
  contactInfoConfig: {
    supportEmail: 'karlsruheapp@karlsruhe.de'
  },
  weatherConfig: {
    location: 'Karlsruhe',
    countryCode2Letters: 'DE',
    goToLink: 'https://web1.karlsruhe.de/wetter'
  },
  appConfig: {
    enablePwa: true,
    enableChat: true,
    enableDevTools: true,
    enableChatDebugMode: false,
    enableEvent: true,
    enableAppStatePersistence: true,
    enableAppBanner: false,
    enableAgb: true,
    enableInviteOnlyNetwork: false,
    enableInviteToNetwork: false,
    enableDisconnectErrorPage: true,
    enableShareMenu: false,
    enableCRMDistributionCancelling: false
  },
  onBoardingConfig: {
    lottieAnimationUrl: '/assets/static/OnboardingKarlsruhe-App_Lottie.json'
  },
  dataProtectionConfig: {
    manifestEnabled: false
  },
  nativeConfig: {
    nativeDeepLink: 'wen-coka',
    useNativeDialog: false,
    storeInfos: {
      androidPlayStoreId: 'network.we.karlsruhe',
      iosAppName: 'karlsruhe-app',
      iosAppStoreId: '1588916244'
    }
  },
  debugOptions: {
    enableAutoRefreshByAssetChanges: true,
    enableNativeBridgeDebug: true
  },
  supportedLanguages: [WenLanguage.GERMAN, WenLanguage.ENGLISH]
};
