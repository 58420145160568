import { Inject, Injectable, Optional, Provider } from '@angular/core';
import {
  ClientCustomizationProvider,
  WEATHER_CONFIG,
  WeatherConfig,
  WenClientStyleConfig,
  WenClientWeatherConfig
} from '@portal/wen-widget';
import { HomeCoverImage, StyleOverrides } from '@portal/wen-backend-api';

@Injectable()
export class CokaClientCustomization extends ClientCustomizationProvider {

  private readonly COLOR_PRIMARY = '#E1001A';
  private readonly COLOR_CHANNEL = '#f2f2f2';

  constructor(@Optional() @Inject(WEATHER_CONFIG) private weatherConfig: WeatherConfig) {
    super();
  }

  getDefaultStyleConfig(): StyleOverrides {
    const config: WenClientStyleConfig = {
      primaryColor: this.COLOR_PRIMARY,
      logo: '/assets/static/logo-karlsruhe.svg',
      homeCoverPortraitImage: '/assets/wen-widget/image/home/home-cover-portrait.jpg',
      homeCoverLandscapeImage: '/assets/wen-widget/image/home/home-cover-portrait.jpg',
      welcomeLogo: '/assets/static/image/start-city-karlsruhe.svg',
      channelViewBackgroundColor: this.COLOR_CHANNEL,
      headerTextColor: '#333',
      headerSubTitleColor: '#767676',
      headerBackgroundColor: 'var(--wen-secondary-color)',
      headerCommandColorBack: 'var(--wen-primary-color)',
      headerCommandColorCreate: 'var(--wen-primary-color)',
      headerCommandColorWizardNext: 'var(--wen-primary-color)',
      headerCommandColorOpenProfile: '#5d5d5d',
      headerCommandColorClose: '#5d5d5d',
      searchBarCancelButtonColor: 'var(--wen-primary-color)',
      searchBarTextColor: '#8e8e93',
      searchBarBackgroundColor: '#e9e9e9',
      errorColor: '#f44336',
      linkColor: '#1467ba',
    };
    return config;
  }

  getDefaultWeatherConfig(): WenClientWeatherConfig {
    return {
      location: this.weatherConfig?.location,
      countryCode2Letters: this.weatherConfig?.countryCode2Letters,
      goToLink: this.weatherConfig?.goToLink
    };
  }

  getDefaultHomeCoverImageConfig(): HomeCoverImage {
    return {
      landscape: '/assets/wen-widget/image/home/home-cover-landscape.png',
      portrait: '/assets/wen-widget/image/home/home-cover-portrait.png'
    };
  }
}

export const COKA_CLIENT_CUSTOMIZATION_PROVIDER: Provider = {
  provide: ClientCustomizationProvider,
  useClass: CokaClientCustomization
};
